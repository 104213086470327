import { FaMobileAlt, FaRegClock } from "react-icons/fa";
import { MenuIcon } from "../../../assets/iconslist";
import logo from "../../../assets/images/logo.jpeg";
import { phone } from "../../../data/constants";
import { TitleWithIcon } from "../../ui/TitleWithIcon/TItleWIthIcon";

import { Box, Typography } from "@material-ui/core";
import color from "../../../variables.scss";

export const Brand = ({ toggleMenuHandler }) => {
  return (
    <Box className="header" display="flex">
      <Box className="header-brand">
        <img
          src={logo}
          alt="logo"
          style={{ height: "50px", marginRight: "10px" }}
        />
        <Typography variant="h1" style={{ fontSize: "30px" }}>
          ZAFFORA <span style={{ color: color["secondary"] }}>MED</span>
        </Typography>
      </Box>

      <Box className="header-contact">
        <TitleWithIcon
          title="Contact Us"
          subtitle={phone}
          icon={<FaMobileAlt color="#07689f" size="35px" />}
        />
        <TitleWithIcon
          title="Opening Hours"
          subtitle="Mon - Fri: 8am to 5pm (EST)"
          icon={<FaRegClock color="#07689f" size="38px" />}
        />
      </Box>
      <Box className="header-toggle-menu">
        <MenuIcon onClick={toggleMenuHandler} />
      </Box>
    </Box>
  );
};
