import {
  Box,
  Button,
  Grid,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { useState } from "react";
import validator from "validator";
import color from "../../variables.scss";

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: "22px",
    display: "flex",
    flexDirection: "column",
  },
  subtitle: {
    marginBottom: "8px",
  },
  button: {
    marginTop: "10px",
    marginRight: "10px",
    width: "100px",
    color: color["white"],
    background: color["primary"],
  },
  formAction: {
    alignSelf: "flex-end",
  },
}));

const ContactUs = () => {
  const [toast, setToast] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [isSending, setIsSending] = useState(false);
  const [contact, setContact] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  const [isError, setError] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    message: false,
  });
  const classes = useStyles();

  const contactHandler = (key, value) => {
    setContact({ ...contact, [key]: value });
  };

  const sendMail = () => {
    if (!validateFields()) {
      return;
    }
    setIsSending(true);
    const data = {
      service_id: "service_pxqg4bj",
      template_id: "template_jl5wp9q",
      user_id: "6Fi-7H0Y6MWn8pGnC",
      template_params: {
        name: `${contact?.firstName} ${contact?.lastName}`,
        message: contact?.message,
        email: contact?.email,
        phone: contact?.phone,
      },
    };

    axios
      .post("https://api.emailjs.com/api/v1.0/email/send", data)
      .then((e) => {
        setToast({
          show: true,
          type: "success",
          message: "Message sent successfully!",
        });
        resetForm();
        setIsSending(false);
      })
      .catch((e) => {
        setToast({
          show: true,
          type: "error",
          message: "Error processing you request. Please try again later",
        });
        setIsSending(false);
      });
  };

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const resetForm = () => {
    setContact({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      message: "",
    });
    setError({
      firstName: false,
      lastName: false,
      phone: false,
      email: false,
      message: false,
    });
  };

  const validateFields = () => {
    const { firstName, lastName, phone, email, message } = contact;
    let error = false;
    if (
      !validator.isAlpha(firstName) ||
      !validator.isLength(firstName, { min: 2 })
    ) {
      setError((prev) => ({ ...prev, firstName: true }));
      error = true;
    } else {
      setError((prev) => ({ ...prev, firstName: false }));
    }
    if (
      !validator.isAlpha(lastName) ||
      !validator.isLength(lastName, { min: 2 })
    ) {
      setError((prev) => ({ ...prev, lastName: true }));
      error = true;
    } else {
      setError((prev) => ({ ...prev, lastName: false }));
    }
    if (!validator.isNumeric(phone) || !validator.isLength(phone, { min: 8 })) {
      setError((prev) => ({ ...prev, phone: true }));
      error = true;
    } else {
      setError((prev) => ({ ...prev, phone: false }));
    }
    if (!validator.isEmail(email)) {
      setError((prev) => ({ ...prev, email: true }));
      error = true;
    } else {
      setError((prev) => ({ ...prev, email: false }));
    }
    if (!validator.isLength(message, { min: 10 })) {
      setError((prev) => ({ ...prev, message: true }));
      error = true;
    } else {
      setError((prev) => ({ ...prev, message: false }));
    }

    if (error) {
      return false;
    }
    return true;
  };

  return (
    <Box classes={{ root: classes.wrapper }}>
      <Typography variant="body1" classes={{ root: classes.subtitle }}>
        Send your request using the form.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            fullWidth
            label="First Name"
            variant="outlined"
            inputProps={{
              maxLength: 25,
            }}
            required
            error={isError?.firstName}
            helperText={isError?.firstName && "Invalid first name"}
            value={contact?.firstName}
            onChange={(e) => contactHandler("firstName", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            fullWidth
            label="Last Name"
            variant="outlined"
            inputProps={{
              maxLength: 25,
            }}
            required
            error={isError?.lastName}
            helperText={isError?.lastName && "Invalid last name"}
            value={contact?.lastName}
            onChange={(e) => contactHandler("lastName", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            required
            inputProps={{
              maxLength: 30,
            }}
            error={isError?.email}
            helperText={isError?.email && "Invalid email"}
            value={contact?.email}
            onChange={(e) => contactHandler("email", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            fullWidth
            label="Phone Number"
            inputProps={{
              maxLength: 15,
            }}
            required
            error={isError?.phone}
            helperText={isError?.phone && "Invalid phone number"}
            variant="outlined"
            value={contact?.phone}
            onChange={(e) => contactHandler("phone", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            fullWidth
            required
            inputProps={{
              maxLength: 500,
            }}
            error={isError?.message}
            helperText={
              isError?.message && "Message must be minimum 10 characters"
            }
            multiline
            minRows={4}
            maxRows={4}
            label="Message"
            variant="outlined"
            value={contact?.message}
            onChange={(e) => contactHandler("message", e.target.value)}
          />
        </Grid>
      </Grid>
      <Box classes={{ root: classes.formAction }}>
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          disabled={isSending}
          disableElevation
          onClick={sendMail}
        >
          Send
        </Button>
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          disableElevation
          onClick={resetForm}
        >
          Cancel
        </Button>
      </Box>
      <Snackbar
        open={toast.show}
        autoHideDuration={6000}
        onClose={() => setToast({ show: false, message: "", type: "" })}
      >
        <Alert
          onClose={() => setToast({ show: false, message: "", type: "" })}
          severity={toast.type}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactUs;
