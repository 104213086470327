import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import color from "../../../variables.scss";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },

  icon: {
    marginRight: "8px",
  },
  title: {
    color: color["primary"],
    fontWeight: "400px",
    fontSize: "15px",
    marginBottom: "5px",
  },
  subtitle: {
    color: color["secondary"],
    fontSize: "13px",
  },
}));

export const TitleWithIcon = ({ icon, title, subtitle }) => {
  const classes = useStyles();

  const Icon = React.cloneElement(icon, {
    className: classes.icon,
  });

  return (
    <Box classes={{ root: classes.wrapper }}>
      {Icon}
      <Box>
        <Typography variant="button" classes={{ root: classes.title }}>
          {title}
        </Typography>
        <Typography variant="body2" classes={{ root: classes.subtitle }}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};
