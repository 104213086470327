import { Grid } from "@material-ui/core";
import { products } from "../../data/products";
import Cards from "../ui/Cards/Cards";

const Products = () => {
  return (
    <Grid container spacing={5}>
      {products.map((p, i) => (
        <Grid key={i} xs={12} sm={6} md={4} lg={4} item>
          <Cards {...p} />
        </Grid>
      ))}
    </Grid>
  );
};
export default Products;
