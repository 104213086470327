import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useEffect, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import { Box, makeStyles, Typography } from "@material-ui/core";
import color from "../../../variables.scss";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: "30px 75px",
    width: "635px",
    "@media screen and (max-width: 769px)": {
      width: "auto",
    },
  },
  title: {
    fontSize: "46px",
    color: "#fff",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: 500,
    margin: "25px 0",
    background: color["primary"],
    display: "inline-block",
    padding: "10px 15px",
    color: "#fff",
    letterSpacing: "3px",
  },
}));

export const Slider = ({ sliderData }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlideHandler = () => {
    setCurrentSlide((slideNumber) =>
      sliderData.length - 1 === slideNumber ? 0 : slideNumber + 1
    );
  };

  const prevSlideHandler = () => {
    setCurrentSlide((slideNumber) =>
      slideNumber === 0 ? sliderData.length - 1 : slideNumber - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => nextSlideHandler(), 3000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  const scrollWithOffset = (el, path) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = path === "#home" ? -150 : -110;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const classes = useStyles();

  return (
    <Box className="slider">
      <Box className="slider-slide-overlay" />
      <IoIosArrowBack
        className="slider-slide-previous"
        onClick={prevSlideHandler}
      />
      <IoIosArrowForward
        className="slider-slide-next"
        onClick={nextSlideHandler}
      />
      {sliderData.map((s, index) => (
        <Box
          key={index}
          className={
            currentSlide === index ? "slider-slide-active" : "slider-slide"
          }
        >
          <Box>
            <img src={s.img} alt="slide" className="slider-slide-image" />
            <Box classes={{ root: classes.wrapper }}>
              <Box>
                <Typography
                  variant="body2"
                  classes={{ root: classes.subtitle }}
                >
                  {s.subTitle}
                </Typography>
                <Typography variant="body2" classes={{ root: classes.title }}>
                  {s.title}
                </Typography>
                <NavHashLink
                  to={s.cta.link}
                  scroll={(el) => scrollWithOffset(el, s.cta.link)}
                  className="slider-slide-cta"
                  smooth
                >
                  <Typography variant="button">{s.cta.title}</Typography>
                </NavHashLink>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
