import { Box, makeStyles, Typography } from "@material-ui/core";
import color from "../../../variables.scss";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alightItems: "center",
    flexDirection: "column",
    padding: "20px 30px",
    borderRadius: "5px",
    background: color["white"],
    "& *": {
      textAlign: "center",
    },
  },
  content: {
    paddingTop: "20px",
  },
  image: {
    height: "150px",
    width: "150px",
    margin: "0 auto",
  },
  title: {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 600,
  },
  subtitle: {
    fontSize: "16px",
    lineHeight: "26px",
    fontWeight: 400,
    marginTop: "10px",
  },
}));

const Cards = ({ image, title, description }) => {
  const classes = useStyles();

  return (
    <Box classes={{ root: classes.wrapper }}>
      <img src={image} alt="product" className={classes.image} />
      <Box classes={{ root: classes.content }}>
        <Typography variant="h4" classes={{ root: classes.title }}>
          {title}
        </Typography>
        <Typography variant="body2" classes={{ root: classes.subtitle }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default Cards;
