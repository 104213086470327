import biopsy from "../assets/images/products/biopsy.jpg";
import infiltration from "../assets/images/products/infiltration.jpg";
import liposuction from "../assets/images/products/liposuction.jpg";
import aspiration from "../assets/images/products/aspiration.jpg";
import custom from "../assets/images/products/custom.jpg";
import urologic from "../assets/images/products/urologic.jpg";

export const products = [
  {
    title: "Infitration Tubing",
    description:
      "The silicone tubing section is designed for increased flow and easy insertion on the pump head",
    image: infiltration,
  },
  {
    title: "Custom Procedure Kits",
    description:
      "The customized procedure kits contain all the medical equipment needed for a specific surgical procedure.",
    image: custom,
  },
  {
    title: "Biopsy Trays",
    description:
      "Soft tissue biopsy trays are available in a variety of configurations to best suit your procedural needs",
    image: biopsy,
  },
  {
    title: "Aspiration Tubing",
    description:
      "The Aspiration Tubing is 147in. (373cm) feet in length and has easy grip ends to ensure a secure fit to your machine and handle",
    image: aspiration,
  },

  {
    title: "Liposuction Tubing",
    description:
      'Heavy-duty liposuction tubing with 1/4" (0.6 cm) wall thickness for strength and flexibility. This highly durable, easy-to-use tubing can be utilized with pressures up to 29.5Hg.',
    image: liposuction,
  },

  {
    title: "Other products",
    description:
      "Urological drain bags | Fat grafting canisters | Fat inducers | Surgical insrument sets | Syringe racks | Syringe transfer systems | Post operative garments etc.",
    image: urologic,
  },
];
