import { Box, Grid, Typography } from "@material-ui/core";
import { NavHashLink } from "react-router-hash-link";
import { phone } from "../../data/constants";

const Footer = () => {
  const scrollWithOffset = (el, path) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = path === "#home" ? -150 : -110;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <Box className="footer">
      <Box className="footer-overlay">
        <Box style={{ padding: "20px" }}>
          <Grid container spacing={5}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ textAlign: "center" }}
            >
              <Typography variant="h6">ZafforaMed</Typography>
              <ul>
                <Typography variant="body2">
                  Zafora Meds is a U.S based asthetic medical devices
                  manufacturing company. Basically we manufacture and supply
                  products like liposuction accessories, medical equipments,
                  scientific equipments, medical furniture and post operative
                  garments.
                </Typography>
              </ul>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={3}
              style={{ textAlign: "center" }}
            >
              <Typography variant="h6">Quick Links</Typography>
              <ul>
                <NavHashLink
                  to="#home"
                  scroll={(el) => scrollWithOffset(el, "#home")}
                  smooth
                >
                  <Typography variant="body2">Home</Typography>
                </NavHashLink>
                <NavHashLink
                  to="#about"
                  scroll={(el) => scrollWithOffset(el, "#about")}
                  smooth
                >
                  <Typography variant="body2">About Us</Typography>
                </NavHashLink>
                <NavHashLink
                  to="#products"
                  scroll={(el) => scrollWithOffset(el, "#products")}
                  smooth
                >
                  <Typography variant="body2">Products</Typography>
                </NavHashLink>
                <NavHashLink
                  to="#contact"
                  scroll={(el) => scrollWithOffset(el, "#contact")}
                  smooth
                >
                  <Typography variant="body2">Contact us</Typography>
                </NavHashLink>
              </ul>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={3}
              style={{ textAlign: "center" }}
            >
              <Typography variant="h6">Products</Typography>
              <ul>
                <Typography variant="body2">Infitration Tubing</Typography>
                <Typography variant="body2">Aspiration Tubing</Typography>
                <Typography variant="body2">Biopsy Trays</Typography>
                <Typography variant="body2">Custom Procedure Kits</Typography>
                <Typography variant="body2">Biopsy Trays</Typography>
              </ul>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              lg={3}
              style={{ textAlign: "center" }}
            >
              <Typography variant="h6">Contact Us</Typography>
              <ul>
                <Typography variant="body2">8 Rebecca Ln</Typography>
                <Typography variant="body2">{phone}</Typography>
                <Typography variant="body2">info@zafforamed.com</Typography>
                <Typography variant="body2">8am - 5pm EST</Typography>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
