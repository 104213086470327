import banner1 from "../assets/images/banner/banner1.jpg";
import banner3 from "../assets/images/banner/banner3.jpg";
import banner5 from "../assets/images/banner/banner5.jpg";

export const sliderData = [
  {
    img: banner1,
    title: "Modern and highly sophisticated instruments.",
    cta: { title: "Get in touch", link: "#contact" },
    subTitle: "We provide",
  },
  {
    img: banner5,
    title: "Manufacturers of general medical devices",
    cta: { title: "Get in touch", link: "#contact" },
    subTitle: "Leading",
  },
  {
    img: banner3,
    title: "Services by well trained, skillful and friendly staff.",
    cta: { title: "Get in touch", link: "#contact" },
    subTitle: "Best customer service",
  },
];
