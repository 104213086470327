import { Typography } from "@material-ui/core";
import { NavHashLink } from "react-router-hash-link";
import Scrollspy from "react-scrollspy";
import { navItems } from "../../../data/navItems";

export const Navbar = ({ toggleMenu, toggleMenuHandler }) => {
  const scrollWithOffset = (el, path) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset =
      path === "#home" ? -150 : window.innerWidth < 769 ? -90 : -110;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div className={`navbar ${toggleMenu && `navbar-show`}`}>
      <Scrollspy
        items={navItems.map(({ id }) => id)}
        currentClassName="navbar_navitems-active"
        offset={-130}
      >
        {navItems.map(({ id, menuName, path }) => (
          <NavHashLink
            className="navbar_navitems"
            to={path}
            smooth
            scroll={(el) => scrollWithOffset(el, path)}
            key={id}
            onClick={() =>
              window.innerWidth < 769 ? toggleMenuHandler() : null
            }
          >
            <Typography style={{ lineHeight: "normal", fontSize: "14px" }}>
              {menuName}
            </Typography>
          </NavHashLink>
        ))}
      </Scrollspy>
    </div>
  );
};
