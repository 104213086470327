import { BrowserRouter } from "react-router-dom";
import AboutUs from "./components/AboutUs/AboutUs";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import { Header } from "./components/Header";
import Products from "./components/Products/Products";
import { Section } from "./components/ui/Sections/Seciton";
import { Slider } from "./components/ui/Slider/Slider";
import { sliderData } from "./data/slider";
import "./global.scss";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <div id="home">
          <Slider sliderData={sliderData} />
        </div>

        <Section title="About Us" id="about">
          <AboutUs />
        </Section>

        <Section title="Our Products" id="products">
          <Products />
        </Section>

        <Section title="Contact Us" id="contact">
          <ContactUs />
        </Section>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
