import { useState } from "react";
import { Brand } from "./Brand/Brand";
import { Navbar } from "./Navigation/Navbar";

export const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const toggleMenuHandler = () => {
    setToggleMenu((prev) => setToggleMenu(!prev));
  };
  return (
    <header
      style={{
        position: "fixed",
        top: 0,
        zIndex: 100,
        width: "100%",
        background: "white",
      }}
    >
      <Brand toggleMenuHandler={toggleMenuHandler} />
      <Navbar toggleMenu={toggleMenu} toggleMenuHandler={toggleMenuHandler} />
    </header>
  );
};
