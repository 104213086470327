import aboutImage from "../../assets/images/about.jpg";
import color from "../../variables.scss";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { NavHashLink } from "react-router-hash-link";

const useStyles = makeStyles(() => ({
  image: {
    height: "330px",
    width: "100%",
    borderRadius: "20px",
  },
  title: {
    fontSize: "18px",
    textTransform: "uppercase",
    lineHeight: "24px",
    marginBottom: "30px",
  },
  description: {
    fontSize: "16px",
    textAlign: "justify",
  },
  contactWrapper: {
    marginTop: "30px",
    "@media screen and (max-width: 530px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  contact: {
    background: color["primary"],
    padding: "15px",
    marginRight: "20px",
    display: "inline-block",
    color: color["white"],
    borderRadius: "10px",
    cursor: "pointer",
    "&:hover": {
      color: color["primary"],
    },
    "@media screen and (max-width: 530px)": {
      width: "100%",
      marginBottom: "10px",
    },
  },
  timing: {
    padding: "15px",
    display: "inline-block",
    color: color["primary"],
    border: `1px solid ${color["primary"]}`,
    borderRadius: "10px",
    "@media screen and (max-width: 530px)": {
      textAlign: "center",
    },
  },
}));

const AboutUs = () => {
  const classes = useStyles();

  const scrollWithOffset = (el, path) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = path === "#home" ? -150 : -110;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <Grid container spacing={5}>
      <Grid item sm={12} xs={12} md={5} lg={5}>
        <img src={aboutImage} alt="about" className={classes.image} />
      </Grid>
      <Grid item sm={12} xs={12} md={7} lg={7}>
        <Typography variant="h4" classes={{ root: classes.title }}>
          Leading manufacturers of general medical devices
        </Typography>
        <Box>
          <Typography variant="body2" classes={{ root: classes.description }}>
            ZafforaMed is a U.S based general medical devices manufacturing
            company. Basically we manufacture and supply products like silicon
            tubings, medical equipments, medical furniture and post operative
            garments. We provide the services by well trained, skillful and
            friendly staff. Our skill have been tested over the years.
          </Typography>
          <Typography variant="body2" classes={{ root: classes.description }}>
            We’re not only here to provide you with Boxerse and superior quality
            equipments as well as other essential components for a medical team/
            hospital but also make sure you are informed and comfortable with
            the whole process. We’ll keep you updated and you feel comfortable
            with our services.
          </Typography>
        </Box>
        <Box classes={{ root: classes.contactWrapper }}>
          <NavHashLink
            to="#contact"
            scroll={(el) => scrollWithOffset(el, "#contact")}
            smooth
          >
            <Button classes={{ root: classes.contact }}>
              Get in touch with us
            </Button>
          </NavHashLink>

          <Typography variant="button" classes={{ root: classes.timing }}>
            Mon - Fri (8am - 5pm EST)
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
export default AboutUs;
