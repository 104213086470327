import { Box, makeStyles, Typography } from "@material-ui/core";
import color from "../../../variables.scss";
const useStyles = makeStyles(() => ({
  wrapper: {
    "&:nth-child(even)": {
      background: "#f4f4f4",
    },
    padding: "50px",
  },

  title: {
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: 600,
    fontSize: "25px",
    color: color["secondary"],
    marginBottom: "50px",
  },
}));

export const Section = ({ title, children, id }) => {
  const classes = useStyles();
  return (
    <Box classes={{ root: classes.wrapper }} id={id}>
      <Typography variant="h3" classes={{ root: classes.title }}>
        {title}
      </Typography>
      <Box>{children}</Box>
    </Box>
  );
};
